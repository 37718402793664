<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>{{ $t('retract') }}</b>
      </h3>
      <hr>
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div>
            <small class="text-muted p-t-30 db">{{ $t('reason') }}</small>
            <wysiwyg v-model="myHTML"/>
            <br>
          </div>
          <div class="form-actions">
            <button class="btn btn-danger" @click="showModal({type:'submit',data:5})">
              <i class="fa fa-times"></i> {{ $t('retract') }}
            </button> &nbsp;
            <button type="button" @click="showModal({type:'cancel',data:0})" class="btn btn-inverse" title="Kembali"> <i class="fa fa-arrow-left"></i> {{ $t('back') }}</button>
          </div>
        </div>
      </div>
    </div>
    <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" :information="isInformation" @close="onClose"/>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import axios from 'axios';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: {
    RotateSquare5
  },
  data() {
    return {
      myHTML: "",
      approver: '',
      isVisible: false,
      modalTitle: '',
      modalContent:'',
      isModalVisible: false,
      isConfirmation:false,
      isInformation:false,
      ActionChangeable:null
    };
  },
  computed: {
    state() {
      return this.$store.state.suratKeluar;
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    onClose(){
      this.isVisible = false;
    },
    showModal(option){
      let vm = this;
      this.isVisible = true;
      if(option.type == 'submit'){
        vm.modalTitle = vm.$t('save_data_q');
        vm.modalContent = "<p>"+ vm.$t('save_data_confirm_q') +"</p>";
        vm.isConfirmation = true;
        vm.isInformation = false;
        vm.ActionChangeable = vm.approve;
      }else if(option.type == 'cancel'){
        vm.modalTitle = vm.$t('cancel_q');
        vm.modalContent = "<p>"+vm.$t('cancel_confirm_q')+"</p>";
        vm.isConfirmation = true;
        vm.ActionChangeable = vm.goBack;
      }
    },
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("suratKeluar/STATE", state);
      this.$store.dispatch(
        "suratKeluar/getSuratKeluarById",
        this.$route.params
      );
    },
    goBack() {
      this.$router.push({
        name: 'suratKeluar'
      });
    },
    approve() {
      if(this.myHTML==""){
        const message = {
          title: this.$t('error'),
          text: this.$t('reason_required'),
          type: "error"
        };
        this.$store.commit("addNotification", message);
        this.isVisible = false;
        return;
      }
      let payload = {
        document: this.$route.params.id,
        approval: 5,
        approver : this.state.detail.approver,
        message: this.myHTML
      };
      this.$store.dispatch("suratKeluar/approveDoc", JSON.stringify(payload));
    },
  }
};
</script>
